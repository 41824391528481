// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-js": () => import("/opt/build/repo/src/components/post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("/opt/build/repo/src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-datenschutz-js": () => import("/opt/build/repo/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-presse-index-js": () => import("/opt/build/repo/src/pages/presse/index.js" /* webpackChunkName: "component---src-pages-presse-index-js" */),
  "component---src-pages-presse-presseartikel-js": () => import("/opt/build/repo/src/pages/presse/presseartikel.js" /* webpackChunkName: "component---src-pages-presse-presseartikel-js" */),
  "component---src-pages-presse-pressebilder-js": () => import("/opt/build/repo/src/pages/presse/pressebilder.js" /* webpackChunkName: "component---src-pages-presse-pressebilder-js" */),
  "component---src-pages-referenzen-js": () => import("/opt/build/repo/src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

